// TODO: Refactor; delete media queries and delete this "disable" rule from file

/* stylelint-disable media-feature-range-notation */

/*
* App Global CSS
* ----------------------------------------------------------------------------
* Put style rules here that you want to apply globally. These styles are for
* the entire app and not just one component. Additionally, this file can be
* used as an entry point to import other CSS/Sass files to be included in the
* output CSS.
* For more information on global stylesheets, visit the documentation:
* https://ionicframework.com/docs/layout/global-stylesheets
*/

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  // This is their supposed brand color, but CAT requested all off-black colors to be pure black instead
  // --brand-color: #1a1818;

  --brand-color: #000;
  --brand-color-secondary: #fc0;
  
  --color-background: white;
  --color-text: black;
  --color-text-focused: black;
  --color-border: black;
  --color-input-background: #f8f8f8;
  --color-hover-link-background: rgb(
    249 250 251
  ); // Tailwind's bg-gray-50 equivalent

  --color-searchbar-background: white;
  --color-menu-border: 	border-color: rgb(229 231 235); // Tailwind's border-gray-200 equivalent

}

// Use for custom color class
// The class must be written in the format .ion-color-{COLOR} where {COLOR}
// Referenced in HTML element as color="brand-red"
// Source: https://ionicframework.com/docs/theming/colors
.ion-color-brand-red {
  --ion-color-base: var(--ion-color-brand-red);
  --ion-color-base-rgb: var(--ion-color-brand-red-rgb);
  --ion-color-contrast: var(--ion-color-brand-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-brand-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-brand-red-shade);
  --ion-color-tint: var(--ion-color-brand-red-tint);
}

// Use for custom color class
// The class must be written in the format .ion-color-{COLOR} where {COLOR}
// Referenced in HTML element as color="custom-green"
// Source: https://ionicframework.com/docs/theming/colors
.ion-color-custom-green {
  --ion-color-base: var(--ion-color-custom-green);
  --ion-color-base-rgb: var(--ion-color-custom-green-rgb);
  --ion-color-contrast: var(--ion-color-custom-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-green-shade);
  --ion-color-tint: var(--ion-color-custom-green-tint);
}

// Use for custom color class
// The class must be written in the format .ion-color-{COLOR} where {COLOR}
// Referenced in HTML element as color="brand-secondary"
// Source: https://ionicframework.com/docs/theming/colors
.ion-color-brand-secondary {
  --ion-color-base: var(--ion-color-brand-secondary);
  --ion-color-base-rgb: var(--ion-color-brand-secondary-rgb);
  --ion-color-contrast: var(--ion-color-brand-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-brand-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-brand-secondary-shade);
  --ion-color-tint: var(--ion-color-brand-secondary-tint);
}

.error {
  color: var(--ion-color-brand-red);
}

.warn {
  color: var(--ion-color-brand-secondary);
}

// Install App Banner ("Add to Home Screen" on mobile)
.a2hs__container {
  background: var(--color-background) !important;

  .a2hs__text {
  color: var(--color-text) !important
  }
}

// Nav Hamburger Menu Button
ion-menu-button {
  color: var(--brand-color-secondary);
}

ion-icon {
  color: var(--brand-color-secondary);
  font-size: 22px;
}

ion-icon.required {
  color: var(--ion-color-brand-red) !important;
}

// Input Field Labels
ion-item.item-has-focus > ion-label {
  color: var(--color-text-focused) !important;
}

// Hamburger menu icon
ion-toolbar ion-menu-button {
  --color: var(--brand-color);
}

  // Slider/Tabs
  // i.e. equipment details - rental rates vs specs
  // i.e. sales hub - revenue vs contracts vs invoices
  ion-content ion-toolbar {
    .custom {
      --background: none;
        width: 100%;

      .ios {
        width: 70vw;
        max-width: 500px;
      }
    }
  }

  // Tabs/segment button styling
  // NOTE: '.md' indicates Android/non-iOS devices
  // i.e. equipment details - rental rates vs specs
  // i.e. sales hub - revenue vs contracts vs invoices
  ion-segment-button.md.custom {
    --color-checked: var(--brand-color-secondary) !important
  }

// segment button styling
ion-segment-button {
  text-transform: uppercase;
}

// Search Bars
ion-searchbar {
  // TODO: Refactor; delete media queries
  // Apply when screen is tablet-size or larger
  @media (min-width: 768px) {
    max-width: 850px;
  }

  .searchbar-input-container {
    // Search Bar Input
    .searchbar-input {
      padding-left: 16px !important;
      background: var(--color-searchbar-background) !important;
    }

    // Search Bar 'Search' Icon
    .searchbar-search-icon {
      color: var(--brand-color-secondary) !important;
    }

    // Search Bar 'X' Clear Icon
    .searchbar-clear-button {
      right: 40px;
    }
  }

  // Move search icon to the right
  ion-icon {
    right: 16px !important;
    left: auto !important;
  }
}

// Header
ion-header {
  ion-title {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: large;
    text-align: center;
    max-height: 60px;
  }

  ion-col {
    text-align: -webkit-center;

    img {
      width: 50vw;
      max-width: 150px;
      margin: 0 auto;
    }
  }
}

a:hover {
  background-color: var(--color-hover-link-background);
}

// Footer
ion-footer {
  margin-top: 12px;

  ion-title {
    text-transform: uppercase;
    font-weight: bolder;
    text-align: center;
    padding-top: 8px;
    position: relative;
  }

  span {
    color: var(--color-text);
  }
}

// Footer - iOS
// Add padding to the left and right of the footer
// Without this, the content inside footer is cut off due to rounded screen shape of some iOS devices
ion-footer.ios {
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 5px;
}

ion-button {
 color: var(--color-text) !important;
 --border-radius: 0.375rem;
}

ion-button.custom {
  width: 100%;
  max-width: 350px;

  text-transform: uppercase;
  text-wrap: balance;
  --color: var(--color-text);
}

ion-button.custom-primary {
  width: 100%;

  text-transform: uppercase;
  text-wrap: balance;
  --background: transparent;
  --color: var(--color-text);
}

ion-button.custom-secondary {
  width: 50%;
  
  text-transform: uppercase;
  text-wrap: balance;
  --color: var(--color-text);
}

// Color of icons inside buttons for .custom 
ion-button.custom ion-icon {
  color: var(--brand-color);
  font-size: large; // smaller than default 22px (applied to all other ion-icons in the app) 
}
// Color of icons inside buttons for .custom-primary
ion-button.custom-primary ion-icon {
  color: var(--brand-color);
  font-size: large; // smaller than default 22px (applied to all other ion-icons in the app) 
}

// Color of icons inside buttons for .custom-secondary
ion-button.custom-secondary ion-icon {
  color: var(--brand-color);
  font-size: large; // smaller than default 22px (applied to all other ion-icons in the app) 
}

// Ensures text color of custom ion-buttons are always black (in both dark and light mode)
ion-button.custom:hover{
  color:black !important;
}

ion-list.list-md {
  padding-top: 0px;
  padding-bottom: 0px;
}

// Ensures text color of ion-item inside ion-menu-toggle (side menu) is always black if it is currently selected (in both dark and light mode)
ion-item.selected {
  --color: black !important; // Text color on Activated}
}

ion-item {

  // invalid underline color
  --highlight-color-invalid: transparent;

  // valid underline color
  --highlight-color-valid: transparent;

  // Color of item on click (click & hold)
  --ripple-color: var(--brand-color-secondary) !important;

  // Color on activated (this applies background colors to mobile platforms)
  --background-activated: var(--brand-color-secondary);
  --background-activated-opacity: var(--brand-color-secondary);
  --color-activated: var(--brand-color-primary); // Text color on Activated

  // Color on activated from user pressing "Tab" to focus on next field
  ion-label.label-stacked.hydrated {
    color: var(--brand-color-primary) !important;
  }

  --highlight-height: 2px;
  --highlight-color-focused: var(--brand-color-secondary);

  // Without this, any touched, invalid ion-inputs make ion-items' bottom border disappear
  &.item-interactive {
    --highlight-background: #e5e7eb;
    --highlight-height: 0px;
  }
}

ion-label.ios {
  font-size: inherit !important;
}

// Increases visibility of disabled items
[disabled] {
  opacity: 0.5 !important;
}

// Increases visibility of disabled items
.item-interactive-disabled * {
  opacity: 0.5 !important;
}

ion-input.custom {
  background: var(--color-input-background);

  --color: var(--color-text);
  --placeholder-color: var(--color-text);
  --placeholder-opacity: 0.8;

  letter-spacing: 1px;

  --padding-bottom: 12px;
  --padding-end: 12px;
  --padding-start: 12px !important;
  --padding-top: 12px;
}

ion-textarea.custom {
  background: var(--color-input-background);

  --color: var(--color-text);
  --placeholder-color: var(--color-text);

  letter-spacing: 1px;

  --padding-bottom: 5px;
  --padding-end: 5px;
  --padding-start: 5px !important;
  --padding-top: 5px;
}

ion-label.custom {
  margin-bottom: 5px !important;
  padding: 2px !important;
}

ion-label[position="stacked"] {
  font-weight: bold;
  text-transform: uppercase;
}

// ion elements with attribute "label" (new syntax for ionic elements)
label > *.label-text-wrapper {
  font-weight: bold;
  text-transform: uppercase;
  opacity: 1;
}

ion-select {
  --placeholder-opacity: 1;
  --border-color: var(--brand-color-secondary);
  --highlight-color-focused: var(--brand-color-secondary);
  --highlight-color: var(--brand-color-secondary);
}

// ion-select - if attribute 'part' has value 'label'
.select-label-placement-stacked::part(label) { 
  font-weight: bold;
  text-transform: uppercase;
}

ion-select.custom-editable {
  border-width: 2px;
  border-color: var(--color-border);
  background: var(--color-input-background);

  --padding-bottom: 12px;
  --padding-end: 12px;
  --padding-start: 12px !important;
  --padding-top: 12px;
}

// Shows blinking cursor when input field is in focus
ion-input, ion-textarea {
  caret-color: var(--color-text);
  min-width: fit-content;
}

// Affects ion-input and ion-textarea when they are in focus (the caret color)
// Without this, the caret color will be green
.ion-valid {
  --highlight-color: var(--color-text) !important;
}

// Nav Back Button - iOS
ion-back-button.ios {
  min-width: 41px;
}

// Nav Back Button
ion-back-button {
  color: var(--brand-color-secondary);
}

ion-fab-button ion-icon {
  color: var(--brand-color);
}

ion-alert {

  .alert-wrapper {
    min-width: 340px !important;
  }

  .alert-radio-label.sc-ion-alert-md {
    color: var(--color-text) !important;
  }

  .alert-checkbox[aria-checked='true'] .alert-checkbox-icon {
    background: var(--brand-color-secondary) !important;
    border-color: var(--brand-color-secondary) !important;
    color: var(--color-text) !important;
  }

  .alert-checkbox-inner {
    border-color: var(--color-text) !important;
  }

  .alert-button-inner {
    color: var(--color-text) !important;
  }

  .alert-radio-inner.sc-ion-alert-md {
    background-color: var(--brand-color-secondary) !important;
  }

  .alert-radio-inner.sc-ion-alert-ios {
    border-color: var(--brand-color-secondary) !important;
  }

  [aria-checked='true'].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
    border-color: var(--brand-color-secondary) !important;
  }

  .alert-radio-label {
    color: var(--brand-color) !important;
  }
}

ion-toast {
  --button-color: var(--brand-color) !important;
}

ion-toast.custom-green-toast {
  &::part(container) {
    background: var(--ion-color-custom-green);
    color: #fff;
    border-color: var(--ion-color-custom-green-shade);
    border-radius: 4px;
  }
}

ion-icon.icon-xl {
  font-size: 5rem;
}

ion-icon.icon-lg {
  font-size: 3rem;
}


ion-menu {
  border-color: var(--color-menu-border);
}

ion-checkbox {
  --placeholder-color: var(--brand-color-secondary) !important;
  --placeholder-opacity: 1 !important;
  --border-color-checked: var(--brand-color-secondary) !important;
  --highlight-color-focused: var(--brand-color-secondary) !important;
  --checkbox-background-checked: var(--ion-color-brand-secondary) !important;
  --checkmark-color: var(--ion-color-brand-secondary-contrast) !important;
}

ion-spinner {
  --color: var(--brand-color-secondary);
}

ion-loading {
  --spinner-color: var(--brand-color-secondary) !important;
}

// Equipment Details - Product Work Order Modal
.product-work-order-modal {
  --min-height: 93vh !important;
}

// Center popovers to the middle of the screen
ion-popover::part(content) {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

ion-popover,
ion-radio.radio-checked,
.item-radio-checked.sc-ion-select-popover-md,
.item-radio-checked.sc-ion-select-popover-ios {
  --background: var(--brand-color-secondary);
  --background-hover: var(--brand-color-secondary);
  --background-focused: var(--brand-color-secondary);
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0 0 0 / 60%);
  --color-checked: var(--brand-color);
  --width: 95vw;
  --max-width: 450px;
}

ion-popover.popover-datetime::part(content) {
  width: max-content !important;
  max-width: 450px;
  min-height: 380px;

  --overflow: hidden;
}

ion-fab-button::part(native) {
  background-color: var(--brand-color-secondary);
  color: var(--brand-color);
}

ion-fab-button::part(native):hover::after {
  background-color: var(--brand-color-secondary);
}

ion-fab-button::part(native):active::after {
  background-color: var(--brand-color-secondary);
}

ion-action-sheet .action-sheet-button {
  color: var(--brand-color) !important;
}

ion-action-sheet button {
  // consistent character spacing/letter-width
  font-variant-numeric: tabular-nums;
}

.no-ripple {
  --background-hover-opacity: 0 !important ;
  --background-focused-opacity: 0 !important;
  --background-activated-opacity: 0 !important;
  --ripple-color: transparent !important;
}

// Disable the scrollbar when zooming in on the PDF
.pz-dragging {
  .ng2-pdf-viewer-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}